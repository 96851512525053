import { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../assets//logo.png";
import { NavActionProps } from "../utils/types/types";
import NavAuth from "./auth";

function MobileMenu({ openNav, setOpenNav }: NavActionProps) {
  useEffect(() => {
    const body = document.body;
    if (openNav) {
      body.classList.add("overflow-hidden");
    } else {
      body.classList.remove("overflow-hidden");
    }

    return () => {
      body.classList.remove("overflow-hidden");
    };
  }, [openNav]);

  return (
    <>
      <div
        className={`fixed  left-0 top-0 w-full transition-all duration-200 ease-in-out text-white bg-cmathsDark/80 backdrop-blur-lg  ${
          openNav
            ? "visible opacity-100 overflow-!hidden"
            : "invisible opacity-0"
        }`}
      >
        <div className="context">
          <div className="relative flex flex-col h-screen justify-between py-5">
            <div className="flex-initial">
              <div className="relative flex justify-between items-center">
                <div className="cmaths-logo">
                  <Link to="/">
                    <img src={Logo} alt="Logo" />
                  </Link>
                </div>
                <NavAuth
                  setOpenNav={setOpenNav}
                  openNav={openNav}
                  className="flex"
                  mobile={true}
                />
              </div>
            </div>
            <div className="mobile-menu-links">
              <ul className="flex flex-wrap flex-col justify-center text-center  gap-y-4">
                <li>
                  <NavLink
                    end
                    to="/"
                    onClick={() => setOpenNav(!openNav)}
                    className={(_) =>
                      _.isActive ? "bg-clip-text bg-primary-gradient text-transparent" : "!text-white"
                    }
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    end
                    to="/tutors/65c7413374027789ccfff261"
                    onClick={() => setOpenNav(!openNav)}
                    className={(_) =>
                      _.isActive ? "bg-clip-text bg-primary-gradient text-transparent" : "!text-white"
                    }
                  >
                    Classes
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    end
                    to="/my-courses"
                    onClick={() => setOpenNav(!openNav)}
                    className={(_) =>
                      _.isActive ? "bg-clip-text bg-primary-gradient text-transparent" : "!text-white"
                    }
                  >
                    My Courses
                  </NavLink>
                </li>
                <li>
                  <a
                    href="https://support.combinedmaths.lk"
                    className="text-white"
                  >
                    Support
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-initial mx-auto">
              <button
                className=" flex justify-center items-center h-10 w-10 rounded-full  bg-white"
                onClick={() => setOpenNav(!openNav)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  stroke="#000000"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileMenu;
